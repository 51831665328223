/* Mukta Mahee */
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Mukta_Mahee/MuktaMahee-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Roboto_Slob/RobotoSlab-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Mukta Mahee;
  src: url('assets/fonts/Roboto_Slob/RobotoSlab-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Roboto slab */
@font-face {
  font-family: Roboto Slab;
  src: url('assets/fonts/Roboto_Slob/RobotoSlab-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Josefin Sans */
@font-face {
  font-family: Josefin Sans;
  src: url('assets/fonts/Josefin\ Sans/JosefinSans-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

/* Cardo */
@font-face {
  font-family: Cardo;
  src: url('assets/fonts/Cardo/Cardo-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* Lusitana  */
@font-face {
  font-family: Lusitana;
  src: url('assets/fonts/Lusitana/Lusitana-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

/* Lustria */
@font-face {
  font-family: Lustria;
  src: url('assets/fonts/Lustria/Lustria-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Poppins */
@font-face {
  font-family: Poppins;
  src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
}
@font-face {
  font-family: Poppins;
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Mukta Mahee Roboto Slab";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "#292929";
  scroll-behavior: smooth;
}
/* body *{
  transition: 0.3s;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* hide functionality */

.show {
  display: none;
}

.hide:hover .show {
  display: block;
}

.hideOnHover {
  display: block;
}

.hideOnHover:hover {
  display: none;
}

.showOnHover {
  display: none;
}

.hideOnHover:hover .showOnHover {
  display: block;
}

/* scroll bar  */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 11px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #EF4823;
  border-radius: 11px;
}
